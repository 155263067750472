<template>
    <div class="error404">
        <div class="main-title">
            <h1>404</h1>
        </div>

        <div class="message">
            O Canada!<br>
            This page doesn't exist.
        </div>

        <div class="face">
            <i class="far fa-frown"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "404"
}
</script>

<style lang="scss" scoped>
    .error404 {
        padding: 300px 0;
        text-align: center;

        h1 {
            font-size: 70px;
        }

        .face {
            margin-top: 40px;
            font-size: 200px;
        }

        .message {
            font-size: 30px;
        }
    }
</style>